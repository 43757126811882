import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import './css/main.css'
import './Header.css';
import logo_h from './vbcontrol_horizontal_dark.svg';
import React, { useState } from 'react';
import { Link } from "react-router-dom";

function Header() {
  const [active, setActive] = useState("");

  const handleClick = (event) => {
    setActive(event.target.id);
  }

  return (
    <span id="header">
      <div class="top"></div>
      <nav class="navbar sticky-top navbar-expand-md bg-dark border-bottom border-body" data-bs-theme="dark">
        <div class="container-sm">
          <a class="navbar-brand" href="#">
            <img src={logo_h} alt="VB Control" width="175"/>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <Link id="1" 
                      className={active === "1" ? "nav-link active active-link" : "nav-link"} 
                      onClick={handleClick} 
                      to="/home">
                      Home
                </Link>
              </li>
              <li class="nav-item">
                <Link id="2" 
                      className={active === "2" ? "nav-link active active-link" : "nav-link"} 
                      onClick={handleClick} 
                      to="/contact">
                      Contact
                </Link>
              </li>
              <li class="nav-item">
                <Link id="3" 
                      className={active === "3" ? "nav-link active active-link" : "nav-link"} 
                      onClick={handleClick} 
                      to="/about">
                      Over ons
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </span>

  );
}

export default Header;
